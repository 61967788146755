import React from 'react';

const ErrorPage = () => {

    return (
        <h1>Page Not Found</h1>    
    )

}

export default ErrorPage;